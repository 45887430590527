ul {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
    margin: 10px 10px;
}

.selected {
    color: blue!important;
}

.ui.placeholder .line {
    background-color: #f3f3f3!important;
}

.question { 
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: center;
}

.question span{
    font-size: 16px;
    font-weight: 600;
    color: #212121;
    margin-bottom: 16px;   
    display: block;
}

.options {
    margin: 48px 0 32px 0;
}

.score {
    font-size: 64px;
    font-weight: 600;
    color: #212121;
    display: block;
    margin-bottom: 16px;
}

.bonnes {
    font-size: 24px;
    font-weight: 600;
    color: #212121;
    margin-bottom: 16px;  
}

.average {
    margin: 16px 0;
}

.average .ui.placeholder .line {
    background-color: #fff!important;
}
  